import React from "react"
import ImageMeta from "../../components/ImageMeta"
import Layout from "../../components/layout"
import Seamless from "../../components/Seamless"
import { SharedStateProvider } from "../../components/SharedState/PageSharedState"

import TheaterVideo from "@components/Theater/TheaterVideo"
import Button from "@bit/azheng.joshua-tree.button"
import ButtonGroup from "@bit/azheng.joshua-tree.button-group"
import CTA from "../../components/CTA/CTA"
import SEO from "../../components/seo"
import TextPhoto from "../../components/TextPhoto/TextPhoto"
import { graphql } from "gatsby"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext,
} from "cloudinary-react"
import { Link } from "gatsby"
import { instructions } from "../../cms/collections/instructions"

var classNames = require("classnames")

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
var converter = new showdown.Converter()

function OurMission(props) {
  return (
    <section 
      className="body-section our-mission color-back"
      style={{backgroundImage: "url('https://res.cloudinary.com/nuvolum/image/upload/v1603732460/MOFS/DEV/about-our-mission.jpg')"}}
    >
      <div
        className={`columns has-text-centered-tablet`}
      >
        <div className={`column is-${props.sideColumnIs}`}></div>
        <div
          className="column is-full-width"
          dangerouslySetInnerHTML={createHtml(
            converter.makeHtml(props.post.ourMission)
          )}
        ></div>
        <div className={`column is-${props.sideColumnIs}`}></div>
      </div>
    </section>
  )
}

export default OurMission
